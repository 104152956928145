import React, { Fragment, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "../../css/custom.css";
import TopNav from "../../components/TopNav/TopNav";
import WaveformAnalysisTab from "../../components/tabs/WaveformAnalysis/WaveformAnalysis";
import TrendingAnalysisTab from "../../components/tabs/TrendingAnalysis/TrendingAnalysis";
import EventsAnalysisTab from "../../components/tabs/EventsAnalysis/EventsAnalysis";
import HarmonicAnalysisTab from "../../components/tabs/HarmonicAnalysisTab";
import DailyReportsTab from "../../components/tabs/DailyReports";
import Alerts from "../../components/tabs/Alerts";
import CommonAPI from "../../api/CommonApi";
import { useDispatch } from "react-redux";
import { equipmentsPortfolioActions } from "../../store/equipmentsPortfolioSlice/equipmentsPortfolioSlice";
import { tabStatusActions } from "../../store/tabStatusSlice/tabStatusSlice";
import Button from "react-bootstrap/Button";
import ScrollButton from "../../components/TopNav/components/ScrollButton/ScrollToTopButton";

const { setIntervalAsync } = require("set-interval-async/fixed");

export default function Dashboard() {
    const token = Cookies.get("Session");
    const dispatch = useDispatch();

    const check_jwt_expiry = () => {
        const token = Cookies.get("Session");
        let tokenDuration = new Date().getTime();
        if (token !== undefined) {
            tokenDuration = new Date(jwt_decode(token).exp * 1000).getTime();
        }

        if (new Date().getTime() >= tokenDuration) {
            Cookies.remove("Session");
            window.location = process.env.REACT_APP_PORTAL_URL + "#/logout";
        }
    };

    useEffect(() => {
        setIntervalAsync(check_jwt_expiry, 10000);
        CommonAPI.getCustomerPortfolio(token).then((response) =>
            dispatch(equipmentsPortfolioActions.setEquipmentPortfolio(response.data.content))
        );
    }, []);

    return (
        <div className="body">
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col>
                            <TopNav />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tabs
                                defaultActiveKey="Waveform Analysis"
                                id="menu-tabs"
                                variant="pills"
                                onSelect={(tabName) => {
                                    dispatch(tabStatusActions.setSelectedTab(tabName));
                                }}
                            >
                                <Tab eventKey="Waveform Analysis" title="Waveform Analysis">
                                    <WaveformAnalysisTab />
                                </Tab>
                                <Tab eventKey="Harmonic Analysis" title="Harmonic Analysis">
                                    <HarmonicAnalysisTab />
                                </Tab>
                                <Tab eventKey="Trending Analysis" title="Trending Analysis">
                                    <TrendingAnalysisTab />
                                </Tab>
                                <Tab eventKey="Events" title="Events Analysis">
                                    <EventsAnalysisTab />
                                </Tab>
                                <Tab eventKey="Reports" title="Daily Reports">
                                    <DailyReportsTab />
                                </Tab>
                                <Tab eventKey="Alerts" title="Alerts">
                                    <Alerts />
                                </Tab>
                            </Tabs>
                        </Col>
                        <ScrollButton />
                    </Row>
                    <Row className="align-content-center">
                        <Col className="mt-5 offset-5">
                            <h6>Version 1.19.0</h6>{" "}
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        </div>
    );
}
