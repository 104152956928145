import authAxios from "../axios/authAxios";

class HarmonicAnalysisApi {
    static getEsaList(accessToken, location_node_id, start_date, end_date, timezone, product_type) {
        return authAxios.get("/v1/common/get_esa_list/", {
            params: {
                location_node_id: location_node_id,
                start_date: start_date,
                end_date: end_date,
                timezone: timezone,
                product_type: product_type,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static get_fft_plot(
        accessToken,
        location_node_id,
        selectedEsa_value,
        axis_type,
        plot_title,
        fft_frequency,
        vfd_driven,
        fft_peaks,
        np_rpm,
        v_type,
        product_type,
        rotor_bars,
        yaxis
    ) {
        // If np_rpm is '---' then make it 0
        if (np_rpm === "---") {
            np_rpm = 0;
        }
        return authAxios.get("/v1/harmonic_analysis/fft_plot_data/", {
            params: {
                location_node_id: location_node_id,
                esa_key: selectedEsa_value,
                xaxis: axis_type,
                plot_title: plot_title,
                fft_range: fft_frequency,
                vfd_driven: vfd_driven,
                fft_peaks: fft_peaks,
                np_rpm: np_rpm,
                v_type: v_type,
                product_type: product_type,
                rotor_bars: rotor_bars,
                yaxis: yaxis,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    static get_harmonic_amp_trending_plot(
        accessToken,
        location_node_id,
        startDate,
        endDate,
        harmonic_freq_lf,
        parameter,
        avg_period,
        search_type,
        vfd_driven,
        timezone,
        v_type,
        tolerance,
        harmonic_scan_plot,
        display_ON_results,
        display_labels,
        subplots,
        work_cycle,
        lf_lower_bound,
        lf_upper_bound,
        display3Phases
    ) {
        const param = [];
        for (const i in subplots) {
            param.push(subplots[i].value);
        }
        let params = {
            location_node_id: location_node_id,
            start_date: startDate,
            end_date: endDate,
            search_harmonic: harmonic_freq_lf,
            parameter: parameter,
            avg_period: avg_period,
            search_type: search_type,
            vfd_driven: vfd_driven,
            timezone: timezone,
            v_type: v_type,
            harmonic_scan_plot: harmonic_scan_plot,
            display_ON_results: display_ON_results,
            display_labels: display_labels,
            subplots: JSON.stringify(param),
            work_cycle: work_cycle,
            lf_lower_bound: lf_lower_bound,
            lf_upper_bound: lf_upper_bound,
            display_3_phase: display3Phases,
        };
        if (!isNaN(tolerance)) {
            params["tolerance"] = tolerance;
        }

        return authAxios.get("/v1/harmonic_analysis/harmonic_trend/", {
            params: params,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    static get_sel_harmonic_plot(
        accessToken,
        location_node_id,
        startDate,
        endDate,
        harmonic_freq_lf,
        parameter,
        avg_period,
        search_type,
        timezone,
        tolerance,
        subplots
    ) {
        const param = [];
        for (const i in subplots) {
            param.push(subplots[i].value);
        }
        let params = {
            location_node_id: location_node_id,
            start_date: startDate,
            end_date: endDate,
            search_harmonic: harmonic_freq_lf,
            parameter: parameter,
            avg_period: avg_period,
            search_type: search_type,
            timezone: timezone,
            subplots: JSON.stringify(param),
        };

        if (!isNaN(tolerance)) {
            params["tolerance"] = tolerance;
        }

        return authAxios.get("/v1/harmonic_analysis/sel_harmonic_trend/", {
            params: params,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    static getDemodulatedPlotData(
        accessToken,
        location_node_id,
        startDate,
        endDate,
        search_harmonic,
        search_type,
        avg_period,
        timezone,
        v_type,
        tolerance
    ) {
        let params = {
            location_node_id: location_node_id,
            start_date: startDate,
            end_date: endDate,
            search_harmonic: search_harmonic,
            search_type: search_type,
            avg_period: avg_period,
            timezone: timezone,
            v_type: v_type,
        };

        if (!isNaN(tolerance)) {
            params["tolerance"] = tolerance;
        }

        return authAxios.get("/v1/harmonic_analysis/demod_harmonic_trend/", {
            params: params,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    static get_hat_report_plot(
        accessToken,
        selectedFacility,
        selectedEquipment,
        location_node_id,
        reportDate,
        vIParameters,
        timezone
    ) {
        return authAxios.get("/v1/harmonic_analysis/hat_report_plot_data/", {
            params: {
                facility_name: selectedFacility,
                node_name: selectedEquipment,
                location_node_id: location_node_id,
                parameter: vIParameters,
                report_date: reportDate,
                timezone: timezone,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    static getNodeESAList(accessToken, location_node_id, date, timezone) {
        return authAxios.get("/v1/common/get_node_esa_list/", {
            params: {
                location_node_id: location_node_id,
                esa_date: date,
                timezone: timezone,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }

    static getEventsList(
        accessToken,
        location_node_id,
        startDate,
        endDate,
        timezone,
        np_current,
        outliers,
        product_type,
        v_type
    ) {
        if (typeof np_current != Number) {
            np_current = 0.1;
        }
        return authAxios.get("/v1/harmonic_analysis/get_events_list/", {
            params: {
                location_node_id: location_node_id,
                start_date: startDate,
                end_date: endDate,
                timezone: timezone,
                np_current: np_current,
                outliers: outliers,
                product_type: product_type,
                v_type: v_type,
            },
            headers: { Authorization: `Bearer ${accessToken}` },
        });
    }
}

export default HarmonicAnalysisApi;
