import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";

import DateRangePicker from "../../../../DatePickers/DateRangePicker/DateRangePicker";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";
import Select from "react-select";

import TrendingAnalysisAPI from "../../../../../api/TrendingAnalysisApi";

const formatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

// Plots available to all nodes
const plotParametersBase = [
    { label: "Voltage", value: "Voltage" },
    { label: "Current", value: "Current" },
    { label: "Frequency", value: "Frequency" },
    { label: "V/I", value: "V/I" },
    { label: "V/I Filtered", value: "V/I Filtered" },
    { label: "Inductance", value: "Inductance" },
    { label: "Inductance Imbalance", value: "Inductance Imbalance" },
    { label: "Resistance", value: "Resistance" },
    { label: "Resistive Imbalance", value: "Resistive Imbalance" },
    { label: "Active Power (kW)", value: "Active Power (kW)" },
];

// Only AC nodes can plot these
const plotParametersAC = [
    { label: "Apparent Power (kVA)", value: "Apparent Power (kVA)" },
    { label: "Reactive Power (kVAR)", value: "Reactive Power (kVAR)" },
    { label: "Power Factor", value: "Power Factor" },
    { label: "Current Imbalance", value: "Current Imbalance" },
    { label: "Current Phase Deviation", value: "Current Phase Deviation" },
];

// Only nodes that have 3 phase voltage in L-L can plot these
const plotParameters3PhaseLL = [
    { label: "Voltage Imbalance", value: "Voltage Imbalance" },
    { label: "Voltage Phase Deviation", value: "Voltage Phase Deviation" },
];

// Only nodes that have a corresponding field node can plot these
const plotParametersPrimary = [
    {
        label: (
            <>
                I/I<sub>field</sub>
            </>
        ),
        value: "I/Ifield",
    },
];

let plotParameters = plotParametersBase;

export default function OneSecondTrendingTab({ tabData, setTabData, setPlotOrder }) {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedCustomer, selectedFacility, selectedEquipment, equipmentTimezone, equipmentsList } = useSelector(
        (state) => state.equipmentsPortfolio
    );
    const selectedVoltageType = useSelector((state) => state.voltageType.selectedVoltageType);

    const [oneSecondNewPlotLoading, setOneSecondNewPlotLoading] = useState(false);
    const [selectedParameters, setSelectedParameters] = useState([
        plotParameters[0],
        plotParameters[1],
        plotParameters[2],
    ]);
    const [oneSecondNewStartDate, setOneSecondNewStartDate] = useState(
        new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 1)
    );
    const [oneSecondNewEndDate, setOneSecondNewEndDate] = useState(new Date());
    const [oneSecondNewEndDateLimit, setOneSecondNewEndDateLimit] = useState(new Date());
    const [oneSecondNewAlert, setOneSecondNewAlert] = useState({
        color: "",
        show: false,
        text: "",
    });

    const plottedEquipment = useRef();
    const plottedVoltageType = useRef();

    const fieldLocationsList = equipmentsList
        .filter((eq) => {
            return eq.location_node_id.split(".")[1] == 1;
        })
        .map((eq) => eq.location_node_id.split(".")[0]);

    const [additionalNodesFlag, setAdditionalNodesFlag] = useState(false);
    const [selectedNodes, setSelectedNodes] = useState([]);

    useEffect(() => {
        if (selectedEquipment == null) {
            setAdditionalNodesFlag(false);
            setSelectedNodes([]);
        } else if (!additionalNodesFlag) {
            setSelectedNodes([selectedEquipment]);
        } else if (selectedNodes.includes(selectedEquipment)) {
            const idx = selectedNodes.indexOf(selectedEquipment);
            setSelectedNodes([selectedEquipment, ...selectedNodes.slice(0, idx), ...selectedNodes.slice(idx + 1, 9)]);
        } else {
            setSelectedNodes([selectedEquipment, ...selectedNodes.slice(0, 9)]);
        }
    }, [selectedEquipment, additionalNodesFlag]);

    const [displayEventsFlag, setDisplayEventsFlag] = useState(false);
    const [displayLabelsFlag, setdisplayLabelsFlag] = useState(false);
    const [display3PhasesFlag, setDisplay3PhasesFlag] = useState(false);

    const updateSelectedNodes = (val) => {
        if (val.length > 10) {
            setOneSecondNewAlert({
                color: "primary",
                text: "No More Than 10 Nodes May Be Selected at Once",
                show: true,
            });
            return;
        }
        if (!val.includes(selectedEquipment)) {
            setOneSecondNewAlert({
                color: "primary",
                text: "Cannot Remove Selected Equipment From Additional Nodes List",
                show: true,
            });
            return;
        }
        if (
            val.some((eq) => eq.eq_type === "dc" || eq.eq_type_sub === "v1" || eq.product_type === "SEL") &&
            selectedVoltageType.value === "L-N"
        ) {
            setOneSecondNewAlert({
                color: "primary",
                text: `${val.pop().label} Can Only Be Added With Line to Line Voltage Selected`,
                show: true,
            });
            return;
        }
        setSelectedNodes(val);
    };

    // Update plot parameter options and selected paramters
    const setPlotParams = () => {
        let newParams = plotParametersBase;

        if (selectedNodes.every((eq) => eq?.eq_type === "ac")) {
            newParams = newParams.concat(plotParametersAC);

            if (selectedVoltageType.value == "L-L" && selectedNodes.every((eq) => eq?.eq_type_sub != "v1")) {
                newParams = newParams.concat(plotParameters3PhaseLL);
            }
        }
        if (
            selectedNodes.every((eq) => {
                const [location, primary] = eq?.location_node_id.split(".");
                return fieldLocationsList.includes(location) && primary == "0";
            })
        ) {
            newParams = newParams.concat(plotParametersPrimary);
        }

        return newParams;
    };
    plotParameters = setPlotParams();

    const filterSelectedParams = () => {
        return selectedParameters.filter((param) => {
            if (plotParameters.some((el) => el.label === param.label)) {
                return true;
            } else {
                return false;
            }
        });
    };
    if (filterSelectedParams().length != selectedParameters.length) {
        setSelectedParameters(filterSelectedParams());
    }

    useEffect(() => {
        if (selectedEquipment) {
            const equipStartDate = new Date(parseInt(selectedEquipment.equipment_start_epoch * 1000));

            if (equipStartDate > oneSecondNewStartDate) {
                setOneSecondNewStartDate(new Date());
                setOneSecondNewEndDate(new Date(new Date().valueOf() + 1000 * 60 * 60 * 24 * 2));
            }
        }
    }, [selectedEquipment]);

    useEffect(() => {
        // only for 3 phase nodes
        if (selectedEquipment && additionalNodesFlag == false) {
            if (
                selectedEquipment?.value == plottedEquipment.current &&
                selectedVoltageType?.value != plottedVoltageType.current &&
                tabData.showPlot
            ) {
                onSecondNewPlotButtonClick();
            }
        }

        //Filter node selection if changed to L-N
        if (selectedVoltageType.value == "L-N") {
            const nodes = selectedNodes.filter(
                (eq) => eq && !(eq.eq_type === "dc" || eq.eq_type_sub === "v1" || eq.product_type === "SEL")
            );
            if (nodes.length != selectedNodes.length) {
                setSelectedNodes(nodes);
                setOneSecondNewAlert({
                    color: "primary",
                    text: "Nodes That Can Only Be Plotted in Line to Line Voltage Have Been Removed",
                    show: true,
                });
            }
        }
    }, [selectedVoltageType]);
    {
        /************************************ 1 second New Trending Functions **************************************************************/
    }

    const onOneSecondNewStartDateChange = (date) => {
        const differceInDays = Math.abs((oneSecondNewEndDateLimit.getTime() - date.getTime()) / (1000 * 3600 * 24));
        setOneSecondNewStartDate(date);

        // Update date limit restrict it to 5 days
        if (differceInDays > 4) {
            setOneSecondNewEndDate(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1));
        } else {
            const tempDate = new Date();
            if (date.toDateString() === tempDate.toDateString()) {
                setOneSecondNewEndDate(new Date());
            } else {
                setOneSecondNewEndDate(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1));
            }
        }

        setOneSecondNewEndDateLimit(
            new Date(Math.min(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 4), new Date()))
        );
    };

    const onOneSecondNewEndDateChange = (date) => {
        setOneSecondNewEndDate(date);
    };

    const onSecondNewPlotButtonClick = () => {
        setOneSecondNewAlert({
            color: "primary",
            show: false,
            text: "",
        });

        if (!selectedCustomer) {
            setOneSecondNewAlert({
                color: "primary",
                show: true,
                text: "Select a Customer",
            });
        } else if (!selectedFacility) {
            setOneSecondNewAlert({
                color: "primary",
                show: true,
                text: "Select a Facility",
            });
        } else if (!selectedEquipment) {
            setOneSecondNewAlert({
                color: "primary",
                show: true,
                text: "Select an Equipment ",
            });
        } else if (selectedParameters.length == 0) {
            setOneSecondNewAlert({
                color: "primary",
                show: true,
                text: "Select at least one Parameter ",
            });
        } else {
            plottedEquipment.current = selectedEquipment.value;
            plottedVoltageType.current = selectedVoltageType.value;

            // Get the filtered params in case plot is called before the state update (i.e changing to L-N causes voltage imbalance to unselect)
            const filteredParams = filterSelectedParams();

            setTabData((prevState) => {
                return {
                    ...prevState,
                    plotData: { current: [], frequency: [], v_i: [], time: [], voltage: [] },
                    plotMetaData: {
                        selectedEquipment: selectedEquipment,
                        selectedNodes: selectedNodes,
                        startDate: formatDate(oneSecondNewStartDate),
                        endDate: formatDate(oneSecondNewEndDate),
                        selectedVoltageType: selectedVoltageType.value,
                        timezone: equipmentTimezone.value,
                        selectedParameters: filteredParams,
                        additionalNodesFlag: additionalNodesFlag,
                        displayEventsFlag: displayEventsFlag,
                        displayLabelsFlag: displayLabelsFlag,
                        display3PhasesFlag: display3PhasesFlag,
                    },
                    showPlot: true,
                    isPlotLoading: true,
                };
            });
            setOneSecondNewPlotLoading(true);

            TrendingAnalysisAPI.getSecondTrendData(
                token,
                selectedEquipment.location_node_id,
                selectedNodes,
                formatDate(oneSecondNewStartDate),
                formatDate(oneSecondNewEndDate),
                equipmentTimezone.value,
                filteredParams,
                selectedVoltageType.value,
                displayEventsFlag,
                displayLabelsFlag,
                display3PhasesFlag
            )
                .then((response) => {
                    if (response.data.content === null) {
                        const text = response.data.info;
                        setOneSecondNewAlert({
                            color: "primary",
                            show: true,
                            text: text,
                        });
                        setOneSecondNewPlotLoading(false);
                        setTabData((prevState) => {
                            return {
                                ...prevState,
                                showPlot: false,
                                isPlotLoading: false,
                            };
                        });
                    } else {
                        setOneSecondNewPlotLoading(false);
                        setTabData((prevState) => {
                            return {
                                ...prevState,
                                plotData: response.data.content,
                                showPlot: true,
                                isPlotLoading: false,
                            };
                        });
                        setPlotOrder({
                            fifteenMinutes: 2,
                            oneSecond: 1,
                            realTimeMetering: 3,
                        });
                    }
                })
                .catch((error) => {
                    setTabData((prevState) => {
                        return {
                            ...prevState,
                            isPlotLoading: false,
                        };
                    });
                    setOneSecondNewPlotLoading(false);
                });
        }
    };

    return (
        <div>
            <Row>
                <Col>
                    <h2 className="margin-top-10">1 Second Trending</h2>{" "}
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>Select Date Range:</p>
                </Col>
            </Row>
            <Row>
                <Col className="waveform-date col-6">
                    <DateRangePicker
                        startDate={oneSecondNewStartDate}
                        endDate={oneSecondNewEndDate}
                        maxEndDate={oneSecondNewEndDateLimit}
                        onStartDateChange={onOneSecondNewStartDateChange}
                        onEndDateChange={onOneSecondNewEndDateChange}
                        openOnStartDateSelect={false}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <br></br>
                    <p>Select Parameters:</p>
                    <Select
                        isMulti
                        isClearable
                        defaultValue={[plotParameters[0], plotParameters[1]]}
                        value={selectedParameters}
                        onChange={(value) => setSelectedParameters(value)}
                        options={plotParameters}
                    />
                    <br></br>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        onChange={(event) => {
                            setDisplayEventsFlag(event.target.checked);
                        }}
                        type="checkbox"
                        label="Display Event Timestamps"
                        checked={displayEventsFlag}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        onChange={(event) => {
                            setdisplayLabelsFlag(event.target.checked);
                        }}
                        type="checkbox"
                        label="Display Equipment Notes"
                        checked={displayLabelsFlag}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        onChange={(event) => {
                            setDisplay3PhasesFlag(event.target.checked);
                        }}
                        type="checkbox"
                        label="Display 3 Phase Data"
                        checked={display3PhasesFlag}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        onChange={(event) => {
                            setAdditionalNodesFlag(event.target.checked);
                        }}
                        type="checkbox"
                        label="Select Additional Nodes"
                        checked={additionalNodesFlag}
                        disabled={!selectedEquipment}
                    />
                </Col>
            </Row>
            {additionalNodesFlag ? (
                <>
                    <Row>
                        <Col>
                            <Select
                                options={equipmentsList}
                                isMulti
                                isClearable
                                defaultValue={selectedEquipment}
                                value={selectedNodes}
                                onChange={(val) => updateSelectedNodes(val)}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <></>
            )}
            <Row>
                <Col className="col-2">
                    {oneSecondNewPlotLoading ? (
                        <ReactLoading type="bars" height={50} width={50} color="#007bff" />
                    ) : (
                        <Button variant="primary" onClick={onSecondNewPlotButtonClick} className="plot_button">
                            Plot
                        </Button>
                    )}
                </Col>
                <Col className="col-8 trendingAlert">
                    <Alert
                        variant={oneSecondNewAlert.color}
                        show={oneSecondNewAlert.show}
                        onClose={() =>
                            setOneSecondNewAlert({
                                ...oneSecondNewAlert,
                                show: false,
                            })
                        }
                        dismissible
                    >
                        {oneSecondNewAlert.text}
                    </Alert>
                </Col>
            </Row>
        </div>
    );
}
