import { meta } from "plotly.js/lib/scatter";

class OneSecNewPlotParser {
    static getEmptyResponse() {
        const plotResponse = {
            time: [],
            frequency: [],
            current: [],
            voltage: [],

            legend_x11: "",

            legend_x21: "Current",
            legend_x22: "NP+SF",

            legend_x31: "Frequency",
            plotTitle: "",
            NP_I_RMS_SF: {
                x: [],
                y: [],
            },
        };
        return plotResponse;
    }
    static getPlotResponseLL(plotData, plotMetaData) {
        const np_sf_yVal = parseFloat(
            (plotMetaData.selectedEquipment.np_sf === 0.1 ? 1.5 : plotMetaData.selectedEquipment.np_sf) *
                plotMetaData.selectedEquipment.np_current
        );
        const plotResponse = {
            NP_I_RMS_SF: {
                x: [plotData.time[0], plotData.time.slice(-1)[0]],
                y: [np_sf_yVal, np_sf_yVal],
            },
            time: plotData.time,
            frequency: plotData.frequency,
            current: plotData.current,
            voltage: plotData.voltage,

            legend_x11: "Voltage L-L",
            legend_x21: "Current",
            legend_x22: "NP + SF",

            legend_x31: "Frequency",

            plotTitle:
                plotMetaData.selectedEquipment.plot_title_node_label +
                ` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)` +
                "<br>Date: " +
                plotMetaData.startDate +
                " to " +
                plotMetaData.endDate +
                " " +
                new Date()
                    .toLocaleDateString("en-US", {
                        timeZone: plotMetaData.timezone,
                        timeZoneName: "short",
                    })
                    .slice(-3),
        };

        if (plotMetaData.selectedEquipment.eq_type === "dc") {
            plotResponse.legend_x11 = "Voltage-DC";
            plotResponse.legend_x21 = "Current-DC";
            var v_i = Array(plotData.voltage.length);
            for (let i = 0; i < plotData.voltage.length; i++) {
                v_i[i] = plotData.voltage[i] / plotData.current[i];
            }
            plotResponse["v_i"] = v_i;
            plotResponse["legend_x31"] = "V/I";
        }
        return plotResponse;
    }

    static getPlotResponseLN(plotData, plotMetaData) {
        const np_sf_yVal = parseFloat(
            (plotMetaData.selectedEquipment.np_sf === 0.1 ? 1.5 : plotMetaData.selectedEquipment.np_sf) *
                plotMetaData.selectedEquipment.np_current
        );
        const plotResponse = {
            NP_I_RMS_SF: {
                x: [plotData.time[0], plotData.time.slice(-1)[0]],
                y: [np_sf_yVal, np_sf_yVal],
            },

            time: plotData.time,
            frequency: plotData.frequency,
            current: plotData.current,
            voltage: plotData.voltage,

            legend_x11: "Voltage L-N",

            legend_x21: "Current",
            legend_x22: "NP + SF",

            legend_x31: "Frequency",

            plotTitle:
                plotMetaData.selectedEquipment.plot_title_node_label +
                ` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)` +
                "<br>Date: " +
                plotMetaData.startDate +
                " to " +
                plotMetaData.endDate +
                " " +
                new Date()
                    .toLocaleDateString("en-US", {
                        timeZone: plotMetaData.timezone,
                        timeZoneName: "short",
                    })
                    .slice(-3),
        };
        return plotResponse;
    }
}

class OneHourParser {
    static parameters = {
        //Dictionary of keys/fields to search for
        Voltage: {
            plotFields: ["voltage", "voltage_ab", "voltage_bc", "voltage_ca"],
            legendFields: ["Voltage L-L", "Voltage-AB", "Voltage-BC", "Voltage-CA"],
            tableField: "voltage",
        },
        Current: {
            plotFields: ["current", "current_a", "current_b", "current_c"],
            legendFields: ["Current", "Current-A", "Current-B", "Current-C"],
            tableField: "current",
        },
        "Line Frequency": {
            plotFields: ["line_frequency"],
            legendFields: ["Line Frequency"],
            tableField: "line_frequency",
        },
        "Voltage Imbalance": {
            plotFields: ["voltage_imbalance"],
            legendFields: ["Voltage Imbalance"],
            tableField: "voltage_imbalance",
        },
        "Current Imbalance": {
            plotFields: ["current_imbalance"],
            legendFields: ["Current Imbalance"],
            tableField: "current_imbalance",
        },
        "Max Voltage Imbalance": {
            plotFields: ["max_voltage_cycle_imbalance"],
            legendFields: ["Max Voltage Imbalance"],
            tableField: "max_voltage_cycle_imbalance",
        },
        "Max Current Imbalance": {
            plotFields: ["max_current_cycle_imbalance"],
            legendFields: ["Max Current Imbalance"],
            tableField: "max_current_cycle_imbalance",
        },
        "Power Factor": {
            plotFields: ["power_factor"],
            legendFields: ["Power Factor"],
            tableField: "power_factor",
        },
        "Power kW": {
            plotFields: ["power_kw"],
            legendFields: ["Power kW"],
            tableField: "power_kw",
        },
        RTD: {
            plotFields: ["ambient_rtd", "max_other_rtd"],
            legendFields: ["Ambient RTD", "Max Other RTD"],
            tableField: "",
        },
        "RTD WDG": {
            plotFields: [
                "rtd_1_wdg",
                "rtd_2_wdg",
                "rtd_3_wdg",
                "rtd_4_wdg",
                "rtd_5_wdg",
                "rtd_6_wdg",
                "rtd_7_wdg",
                "rtd_8_wdg",
            ],
            legendFields: [
                "RTD 1 WDG",
                "RTD 2 WDG",
                "RTD 3 WDG",
                "RTD 4 WDG",
                "RTD 5 WDG",
                "RTD 6 WDG",
                "RTD 7 WDG",
                "RTD 8 WDG",
            ],
            tableField: "",
        },
        "Motor Load xFLA1": {
            plotFields: ["motor_load_xFLA1"],
            legendFields: ["Motor Load xFLA1"],
            tableField: "",
        },
        "TCU Prcnt": {
            plotFields: ["stator_tcu_prcnt", "rotor_tcu_prcnt", "rtd_tcu_prcnt"],
            legendFields: ["Stator TCU Prcnt", "Rotor TCU Prcnt", "RTD TCU Prcnt"],
            tableField: "",
        },
        "Starts Available": {
            plotFields: ["starts_available"],
            legendFields: ["Starts Available"],
            tableField: "",
        },
    };

    /*
	Inputs: 
		plotData: plot_data object returned within the 'getHourlyTrendData' api call
		plotMetaData: metaData containing selectedEquipment, startDate, endDate, and selectedVoltageType.value - Typically set in tabData and given to the plot as a prop
	Outputs:
		Object containing layout and data parameters to be used by a plotly plot
	*/
    static getPlotResponse(plotData, plotMetaData) {
        //console.log(plotData)
        const parameters = this.parameters;
        const plotTitle =
            plotMetaData.selectedEquipment.plot_title_node_label +
            ` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)` +
            "<br>Date: " +
            plotMetaData.startDate +
            " to " +
            plotMetaData.endDate +
            " " +
            new Date()
                .toLocaleDateString("en-US", {
                    timeZone: plotMetaData.timezone,
                    timeZoneName: "short",
                })
                .slice(-3);

        const [rangeStart, rangeEnd] = getDateRange(plotMetaData.startDate, plotMetaData.endDate);

        const layout = {
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            xaxis: {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: "Date - Time",
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
                range: [rangeStart, rangeEnd],
            },
            title: {
                font: { size: 22 },
                x: 0.07,
                align: "top",
                text: plotTitle,
            },
            legend: { font: { size: 18 } },
            showlegend: true,
        };

        const markerColors = ["green", "black", "red", "blue"];
        const x = plotData.time;

        var data = [];
        var annotations = [];
        var count = 0;

        for (const subplot in parameters) {
            if (
                plotData[parameters[subplot]["plotFields"][0]] == null ||
                plotData[parameters[subplot]["plotFields"][0]].length <= 0
            )
                continue;

            count++;

            //Format if Y-axis name is too long
            var yTitle = subplot;
            if (yTitle.length > 20) {
                //replace the space at arbitrary point near middle of text with a \n
                const index = yTitle.indexOf(" ", Math.floor(yTitle.length / 2) - 3);
                yTitle = subplot.substring(0, index) + "<br>" + subplot.substring(index + 1);
            }

            layout[`yaxis${count}`] = {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: yTitle,
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            };

            const subplotTitle = {
                text: subplot,
                font: { size: 18 },
                xref: "paper",
                yref: `y${count} domain`,
                x: 0.5,
                y: 0.99,
                yanchor: "bottom",
                showarrow: false,
            };
            annotations.push(subplotTitle);
            for (const [idx, field] of parameters[subplot]["plotFields"].entries()) {
                if (plotData[field] == null || plotData[field].length <= 0) continue;

                const trace = {
                    x: x,
                    y: plotData[field],
                    yaxis: `y${count}`,
                    type: "scatter",
                    name: parameters[subplot]["legendFields"][idx],
                    marker: { color: markerColors[idx] },
                    connectgaps: true,
                };
                data.push(trace);
            }
        }

        layout["grid"] = { rows: count, columns: 1 };
        layout["height"] = count === 1 ? 400 : count * 300;
        layout["annotations"] = annotations;
        return { layout, data };
    }

    /*
	Inputs: Raw table data from 'getHourlyTrendData' api call 
	Outputs: Object containing columns, table data, with a keyfield for a bootstrap table
	*/
    static getTableResponse(tableData) {
        const parameters = this.parameters;

        const columns = [{ dataField: "measurement", text: "" }];

        const avg_data = { measurement: "Average" };
        const min_data = { measurement: "Min" };
        const max_data = { measurement: "Max" };

        for (const subplot in parameters) {
            const field = parameters[subplot]["tableField"];
            const avg = tableData[`${field}_avg`];
            const min = tableData[`${field}_min`];
            const max = tableData[`${field}_max`];
            if (avg != null) {
                columns.push({ dataField: field, text: subplot });
                avg_data[field] = avg;
                min_data[field] = min;
                max_data[field] = max;
            }
        }

        const data = Object.keys(avg_data).length > 1 ? [avg_data, min_data, max_data] : [];

        return {
            columns,
            data,
            keyField: "measurement",
        };
    }
}

// const average = (array) => {
//     var sum = 0;
//     var count = 0;
//     for (const num of array) {
//         if (isNaN(num) || num == null || num == 0) continue;
//         sum += num;
//         count += 1;
//     }
//     const avg = sum / count;
//     return isNaN(avg) ? "---" : avg.toFixed(2);
// };

// const minimum = (array) => {
//     var min = Infinity;
//     for (const num of array) {
//         if (isNaN(num) || num == null || num == 0) continue;
//         if (num < min) min = num;
//     }
//     return min === Infinity ? "---" : min.toFixed(2);
// };

// const maximum = (array) => {
//     var max = -Infinity;
//     for (const num of array) {
//         if (isNaN(num) || num == null || num == 0) continue;
//         if (num > max) max = num;
//     }
//     return max === -Infinity ? "---" : max.toFixed(2);
// };

const getDateRange = (startDate, endDate) => {
    const rangeStart = new Date(startDate);
    rangeStart.setDate(rangeStart.getUTCDate());
    rangeStart.setHours(0, 0, 0);

    const rangeEnd = new Date(endDate);
    rangeEnd.setDate(rangeEnd.getUTCDate());
    rangeEnd.setHours(23, 59, 59);
    return [rangeStart, rangeEnd];
};

const formatText = (text, maxLength) => {
    const words = text.split(" ");
    const lines = [];
    let currentPart = "";

    for (const word of words) {
        if ((currentPart + " " + word).trim().length <= maxLength) {
            currentPart = (currentPart + " " + word).trim();
        } else {
            lines.push(currentPart);
            currentPart = word;
        }
    }
    if (currentPart !== "") {
        lines.push(currentPart);
    }

    return "&nbsp;<br>" + lines.join("<br>") + "<br>&nbsp;";
};

const estimateY = (time, phase) => {
    const timeArr = phase.x;
    const t = new Date(time);

    let lowerTimeIndex = 0;
    let upperTimeIndex = timeArr.length - 1;

    if (t < new Date(timeArr[lowerTimeIndex])) {
        upperTimeIndex = 0;
    } else if (t > new Date(timeArr[upperTimeIndex])) {
        lowerTimeIndex = upperTimeIndex;
    } else {
        while (lowerTimeIndex < upperTimeIndex) {
            const midIndex = Math.floor((lowerTimeIndex + upperTimeIndex) / 2);
            if (t < new Date(timeArr[midIndex])) {
                upperTimeIndex = midIndex;
            } else if (t > new Date(timeArr[midIndex])) {
                lowerTimeIndex = midIndex + 1;
            } else {
                lowerTimeIndex = midIndex;
                upperTimeIndex = midIndex;
            }
        }
        lowerTimeIndex = lowerTimeIndex - 1;
    }

    const slope =
        upperTimeIndex === lowerTimeIndex
            ? 0
            : (phase.y[upperTimeIndex] - phase.y[lowerTimeIndex]) /
              (new Date(phase.x[upperTimeIndex]).getTime() - new Date(phase.x[lowerTimeIndex]).getTime());
    const dx = new Date(time).getTime() - new Date(phase.x[lowerTimeIndex]).getTime();
    const y_guess = slope * dx + phase.y[lowerTimeIndex];

    return y_guess;
};

class OneSecParser {
    static parseData(plotData, metaData) {
        const parameters = {
            Voltage: {
                plotName: "Voltage",
                yAxisName: "Voltage (V)",
                plotFields: ["voltage", "voltage_ab", "voltage_bc", "voltage_ca"],
                legendNames: ["Voltage", "Voltage-AB", "Voltage-BC", "Voltage-CA"],
                traceColor: ["green", "black", "red", "blue"],
                tableName: "Voltage",
                tableField: "voltage",
            },
            Current: {
                plotName: "Current",
                yAxisName: "Current (A)",
                plotFields: ["current", "current_a", "current_b", "current_c"],
                legendNames: ["Current", "Current-A", "Current-B", "Current-C"],
                traceColor: ["green", "black", "red", "blue"],
                tableName: "Current",
                tableField: "current",
            },
            Frequency: {
                plotName: "Frequency",
                yAxisName: "Frequency (Hz)",
                plotFields: ["frequency"],
                legendNames: ["Frequency"],
                traceColor: ["green"],
                tableName: "Frequency",
                tableField: "frequency",
            },
            "V/I": {
                plotName: "V/I",
                yAxisName: "V/I (Ω)",
                plotFields: ["v_i"],
                legendNames: ["V/I"],
                traceColor: ["green"],
                tableName: "V/I",
                tableField: "v_i",
            },
            "V/I Filtered": {
                plotName: "V/I Filtered",
                yAxisName: "V/I Filtered (Ω)",
                plotFields: ["v_i_filtered"],
                legendNames: ["V/I Filtered"],
                traceColor: ["green"],
                tableName: "V/I Filtered",
                tableField: "v_i_filtered",
            },
            "I/Ifield": {
                plotName: "I/I<sub>field</sub>",
                yAxisName: "I/I<sub>field</sub>",
                plotFields: ["I/Ifield"],
                legendNames: ["I/I<sub>field</sub>"],
                traceColor: ["red"],
                tableName: (
                    <>
                        I/I<sub>field</sub>
                    </>
                ),
                tableField: "I/Ifield",
            },
            "Active Power (kW)": {
                plotName: "Active Power (kW)",
                yAxisName: "kW",
                plotFields: ["kw"],
                legendNames: ["kW"],
                traceColor: ["green"],
                tableName: "kW",
                tableField: "kw",
            },
            "Apparent Power (kVA)": {
                plotName: "Apparent Power (kVA)",
                yAxisName: "kVA",
                plotFields: ["kva"],
                legendNames: ["kVA"],
                traceColor: ["green"],
                tableName: "kVA",
                tableField: "kva",
            },
            "Power Factor": {
                plotName: "Power Factor",
                yAxisName: "",
                plotFields: ["power_factor"],
                legendNames: ["Power Factor"],
                traceColor: ["green"],
                tableName: "Power Factor",
                tableField: "power_factor",
            },
            "Reactive Power (kVAR)": {
                plotName: "Reactive Power (kVAR)",
                yAxisName: "kVAR",
                plotFields: ["kvar"],
                legendNames: ["kVAR"],
                traceColor: ["green"],
                tableName: "kVAR",
                tableField: "kvar",
            },
            Inductance: {
                plotName: "Inductance",
                yAxisName: "Inductance (H)",
                plotFields: ["inductance"],
                legendNames: ["Inductance"],
                traceColor: ["green"],
                tableName: "Inductance",
                tableField: "inductance",
            },

            "Inductance Imbalance": {
                plotName: "Inductance Imbalance",
                yAxisName: "Inductance Imbalance %",
                plotFields: ["inductance_imbalance_a", "inductance_imbalance_b", "inductance_imbalance_c"],
                legendNames: ["Inductance Imbalance - A", "Inductance Imbalance - B", "Inductance Imbalance - C"],
                traceColor: ["black", "red", "blue"],
                tableName: "Inductance Imbalance",
                tableField: null,
            },

            Resistance: {
                plotName: "Resistance",
                yAxisName: "Resistance (Ω)",
                plotFields: ["resistance", "resistance_a", "resistance_b", "resistance_c"],
                legendNames: ["Resistance", "Resistance A", "Resistance B", "Resistance C"],
                traceColor: ["green", "black", "red", "blue"],
                tableName: "Resistance",
                tableField: "resistance",
            },

            "Resistive Imbalance": {
                plotName: "Resistive Imbalance",
                yAxisName: "Resistive Imbalance %",
                plotFields: ["resistive_imbalance_a", "resistive_imbalance_b", "resistive_imbalance_c"],
                legendNames: ["Resistive Imbalance A", "Resistive Imbalance B", "Resistive Imbalance C"],
                traceColor: ["black", "red", "blue"],
                tableName: "Resistive Imbalance",
                tableField: null,
            },
            "Voltage Imbalance": {
                plotName: "Voltage Imbalance",
                yAxisName: "Voltage Imbalance (%)",
                plotFields: ["voltage_imbalance"],
                legendNames: ["Voltage Imbalance"],
                traceColor: ["green"],
                tableName: "Voltage Imbalance",
                tableField: null,
            },
            "Current Imbalance": {
                plotName: "Current Imbalance",
                yAxisName: "Current Imbalance (%)",
                plotFields: ["current_imbalance"],
                legendNames: ["Current Imbalance"],
                traceColor: ["green"],
                tableName: "Current Imbalance",
                tableField: null,
            },
            "Current Phase Deviation": {
                plotName: "Current Phase Deviation",
                yAxisName: "Current Deviation (%)",
                plotFields: ["current_deviation_a", "current_deviation_b", "current_deviation_c"],
                legendNames: ["Current Deviation-A", "Current Deviation-B", "Current Deviation-C"],
                traceColor: ["black", "red", "blue"],
                tableName: "Current Phase Deviation",
                tableField: null,
            },
            "Voltage Phase Deviation": {
                plotName: "Voltage Phase Deviation",
                yAxisName: "Voltage Deviation (%)",
                plotFields: ["voltage_deviation_ab", "voltage_deviation_bc", "voltage_deviation_ca"],
                legendNames: ["Voltage Deviation-AB", "Voltage Deviation-BC", "Voltage Deviation-CA"],
                traceColor: ["black", "red", "blue"],
                tableName: "Voltage Phase Deviation",
                tableField: null,
            },
        };

        const selectedParameters = metaData.selectedParameters.map((param) => param.value);

        //-------Setup Plot layout-------//
        const plotTitle =
            metaData.selectedNodes
                .map((eq) => {
                    return (
                        eq.plot_title_node_label +
                        ` (${eq.np_voltage}V, ${eq.np_current}A, ${eq.np_rpm}RPM, ${eq.np_hp}HP)`
                    );
                })
                .join("<br>") +
            "<br>Date: " +
            metaData.startDate +
            " to " +
            metaData.endDate +
            " " +
            new Date()
                .toLocaleDateString("en-US", {
                    timeZone: metaData.timezone,
                    timeZoneName: "short",
                })
                .slice(-3);

        const selectedNodeCount = Object.keys(metaData.selectedNodes).length;
        const subplotCount = Object.keys(selectedParameters).length;

        const titleHeight = 27 + selectedNodeCount * 28.6;
        const titleMargin = titleHeight + 44.4;
        const plotHeight = (subplotCount === 1 ? 400 : subplotCount * 300) + titleMargin;
        const titleLocation = 1 - 30 / plotHeight;

        const [rangeStart, rangeEnd] = getDateRange(metaData.startDate, metaData.endDate);

        const layout = {
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            xaxis: {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: "Date - Time",
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
                range: [rangeStart, rangeEnd],
            },
            title: {
                font: { size: 22 },
                x: 0.07,
                text: plotTitle,
                y: titleLocation,
                yref: "container",
                automargin: true,
            },
            legend: {
                font: { size: 18 },
            },
            showlegend: true,
            margin: {
                t: titleMargin,
            },
        };

        var annotations = [];
        var count = 0;
        for (const subplot of selectedParameters) {
            count++;

            //Format if Y-axis name is too long
            //Replace ignores any html tags so only display name is measured
            var yTitle = parameters[subplot]["yAxisName"];
            if (yTitle.replace(/(<([^>]+)>)/gi, "").length > 20) {
                //replace the space at arbitrary point near middle of text with a break
                const index = yTitle.replace(/(<([^>]+)>)/gi, "").indexOf(" ", Math.floor(yTitle.length / 2) - 3);
                yTitle = yTitle.substring(0, index) + "<br>" + yTitle.substring(index + 1);
            }

            layout[`yaxis${count}`] = {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: yTitle,
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            };

            const subplotTitle = {
                text: parameters[subplot]["plotName"],
                font: { size: 18 },
                xref: "paper",
                yref: `y${count} domain`,
                x: 0.5,
                y: 0.99,
                yanchor: "bottom",
                showarrow: false,
            };
            annotations.push(subplotTitle);
        }

        //-------Create each trace and add to plot---------//
        const data = [];
        var nodeCount = 0;
        const colorway = [
            "blue",
            "orange",
            "green",
            "purple",
            "#1f77b4", // muted blue
            "#F56600", // darker orange
            "#8c564b", // chestnut brown
            "#e377c2", // raspberry yogurt pink
            "#7f7f7f", // middle gray
            "#17becf", // blue-teal
        ];
        for (const node of metaData.selectedNodes) {
            const location_node_id = node.location_node_id;
            if (
                plotData[location_node_id] == null ||
                plotData[location_node_id]["time"] == null ||
                plotData[location_node_id]["time"].length === 0
            )
                continue;

            //-------Pre-Processing-------//
            for (const param of selectedParameters) {
                if (param === "Voltage") {
                    if (metaData.additionalNodesFlag === false) {
                        const postfix =
                            metaData.selectedEquipment.eq_type === "dc" ? "-DC" : ` ${metaData.selectedVoltageType}`;
                        parameters["Voltage"]["legendNames"][0] += postfix;
                        parameters["Voltage"]["tableName"] += postfix;
                    }

                    if (
                        metaData.selectedVoltageType != "L-L" ||
                        !metaData.display3PhasesFlag ||
                        metaData.selectedEquipment.eq_type == "dc" ||
                        metaData.selectedEquipment.eq_type_sub == "v1"
                    ) {
                        parameters["Voltage"]["plotFields"] = parameters["Voltage"]["plotFields"].slice(0, 1);
                        parameters["Voltage"]["traceColor"] = ["blue"];
                    }
                } else if (param === "Current") {
                    if (metaData.additionalNodesFlag === false) {
                        const postfix = metaData.selectedEquipment.eq_type === "dc" ? "-DC" : "";
                        parameters["Current"]["legendNames"][0] += postfix;
                        parameters["Current"]["tableName"] += postfix;
                    }

                    if (
                        !metaData.display3PhasesFlag ||
                        metaData.selectedEquipment.eq_type == "dc" ||
                        metaData.selectedEquipment.eq_type_sub == "i1"
                    ) {
                        parameters["Current"]["plotFields"] = parameters["Current"]["plotFields"].slice(0, 1);
                    }
                } else if (param === "I/Ifield") {
                    //Create I/Ifield data
                    const plotField = parameters[param]["plotFields"][0];
                    if (
                        plotData[location_node_id]["field_current"] == null ||
                        plotData[location_node_id]["current"] == null
                    ) {
                        console.log("Missing data requirements for the I/Ifield plot");
                        continue;
                    }

                    const i_noise = node.i_noise;
                    const i_ifield = Array(plotData[location_node_id]["current"].length);
                    for (let i = 0; i < plotData[location_node_id]["current"].length; i++) {
                        if (plotData[location_node_id]["field_current"][i] === 0) {
                            plotData[location_node_id]["field_current"][i] = 0.00001;
                        }

                        i_ifield[i] =
                            plotData[location_node_id]["current"][i] > i_noise
                                ? Math.round(
                                      (plotData[location_node_id]["current"][i] /
                                          plotData[location_node_id]["field_current"][i]) *
                                          1e3
                                  ) / 1e3
                                : 0;
                    }
                    plotData[location_node_id][plotField] = i_ifield;
                }
            }

            const x = plotData[location_node_id].time;

            //Create plot data
            var count = 0;
            for (const subplot of selectedParameters) {
                if (plotData[location_node_id][parameters[subplot]["plotFields"][0]] == null) continue;
                count++;

                for (const [idx, field] of parameters[subplot]["plotFields"].entries()) {
                    if (plotData[location_node_id][field] == null) continue;

                    const trace = {
                        x: x,
                        y: plotData[location_node_id][field],
                        yaxis: `y${count}`,
                        type: "scatter",
                        name: metaData.additionalNodesFlag ? node.label : parameters[subplot]["legendNames"][idx],
                        csvName:
                            (metaData.additionalNodesFlag ? node.label + " - " : "") +
                            parameters[subplot]["legendNames"][idx],
                        marker: {
                            color: metaData.additionalNodesFlag
                                ? colorway[nodeCount % colorway.length]
                                : parameters[subplot]["traceColor"][idx],
                        },
                        legendgroup: metaData.additionalNodesFlag
                            ? `${location_node_id}`
                            : parameters[subplot]["legendNames"][idx],
                        showlegend: metaData.additionalNodesFlag ? count === 1 : true,
                    };
                    const fillerTrace = {
                        x: x,
                        y: plotData[location_node_id][field],
                        yaxis: `y${count}`,
                        type: "scatter",
                        name: metaData.additionalNodesFlag ? node.label : parameters[subplot]["legendNames"][idx],
                        marker: {
                            color: metaData.additionalNodesFlag
                                ? colorway[nodeCount % colorway.length]
                                : parameters[subplot]["traceColor"][idx],
                        },
                        legendgroup: metaData.additionalNodesFlag
                            ? `${location_node_id}`
                            : parameters[subplot]["legendNames"][idx],
                        showlegend: false,
                        line: {
                            dash: "2px,4px",
                            width: "2",
                        },
                        connectgaps: true,
                    };
                    data.push(trace, fillerTrace);
                }
                //Add NP + SF trace for current plot
                if (subplot === "Current" && metaData.additionalNodesFlag === false) {
                    const eq = metaData.selectedEquipment;
                    const yVal = parseFloat((eq.np_sf === 0.1 ? 1.15 : eq.np_sf) * eq.np_current);
                    const npsf_trace = {
                        x: [x[0], x.slice(-1)[0]],
                        y: [yVal, yVal],
                        yaxis: `y${count}`,
                        type: "scatter",
                        name: "NP + SF",
                        marker: { color: "black" },
                    };
                    data.push(npsf_trace);
                }
                //Add event features
                if (subplot === "Current" && metaData.displayEventsFlag === true) {
                    const events = plotData[location_node_id]["event_data"];

                    const current_rise_trace = {
                        x: events["current_rise"]["x"],
                        y: events["current_rise"]["y"],
                        yaxis: `y${count}`,
                        type: "scatter",
                        mode: "markers",
                        name: "current_rise",
                        marker: { color: "black" },
                        legendgroup: metaData.additionalNodesFlag ? `${location_node_id}` : "",
                        showlegend: metaData.additionalNodesFlag ? false : true,
                    };
                    if (current_rise_trace.x.length) data.push(current_rise_trace);

                    const current_fall_trace = {
                        x: events["current_fall"]["x"],
                        y: events["current_fall"]["y"],
                        yaxis: `y${count}`,
                        type: "scatter",
                        mode: "markers",
                        name: "current_fall",
                        marker: { color: "black" },
                        legendgroup: metaData.additionalNodesFlag ? `${location_node_id}` : "",
                        showlegend: metaData.additionalNodesFlag ? false : true,
                    };
                    if (current_fall_trace.x.length) data.push(current_fall_trace);
                }
                if (subplot === "Voltage" && metaData.displayEventsFlag === true) {
                    const events = plotData[location_node_id]["event_data"];

                    const voltage_rise_trace = {
                        x: events["voltage_rise"]["x"],
                        y: events["voltage_rise"]["y"],
                        yaxis: `y${count}`,
                        type: "scatter",
                        mode: "markers",
                        name: "voltage_rise",
                        marker: { color: "black" },
                        legendgroup: metaData.additionalNodesFlag ? `${location_node_id}` : "",
                        showlegend: metaData.additionalNodesFlag ? false : true,
                    };
                    if (voltage_rise_trace.x.length) data.push(voltage_rise_trace);

                    const voltage_fall_trace = {
                        x: events["voltage_fall"]["x"],
                        y: events["voltage_fall"]["y"],
                        yaxis: `y${count}`,
                        type: "scatter",
                        mode: "markers",
                        name: "voltage_fall",
                        marker: { color: "black" },
                        legendgroup: metaData.additionalNodesFlag ? `${location_node_id}` : "",
                        showlegend: metaData.additionalNodesFlag ? false : true,
                    };
                    if (voltage_fall_trace.x.length) data.push(voltage_fall_trace);
                }

                //Add notes
                if (count === 1 && metaData.displayLabelsFlag) {
                    const max_y_plot = data.reduce((max, curr) => {
                        // Need additional reduce because return Math.max(...curr.y, max) will exceed stack
                        const localMax = curr.y.reduce((max, curr) => Math.max(max, curr), -Infinity);
                        return Math.max(localMax, max);
                    }, -Infinity);

                    const notes = plotData[location_node_id]?.notes.x.map((time, i) => {
                        const max_y_at_index = data.reduce((max, curr) => {
                            if (
                                curr["name"] === "NP + SF" ||
                                (metaData.additionalNodesFlag && curr.legendgroup != location_node_id)
                            ) {
                                return max;
                            }
                            const estimate = estimateY(time, curr);
                            return estimate ? Math.max(max, estimate) : max;
                        }, 0);

                        const text = formatText(plotData[location_node_id].notes.text[i], 30);

                        return {
                            x: time,
                            y: max_y_at_index,
                            xref: "x",
                            yref: "y",
                            text: text,
                            showarrow: true,
                            arrowhead: 0,
                            ayref: "y",
                            ay: max_y_plot,
                            yanchor: "bottom",
                            yshift: 12,
                            ax: 0,
                        };
                    });

                    annotations = annotations.concat(notes);
                }
            }
            nodeCount++;
        }

        layout["grid"] = { rows: subplotCount, columns: 1 };
        layout["height"] = plotHeight;
        layout["annotations"] = annotations;

        const plot = { layout, data };

        //----Creating Table----//
        function tableFormat(cell, row, rowIndex, colIndex) {
            if (rowIndex % 3) {
                return { style: { display: "none" } };
            } else {
                return { rowSpan: 3 };
            }
        }
        const columns = [
            { dataField: "label", text: "Node SN", attrs: tableFormat },
            { dataField: "measurement", text: "" },
        ];

        //Create columns
        for (const subplot of selectedParameters) {
            const text = parameters[subplot]["tableName"];
            const field = parameters[subplot]["tableField"];

            columns.push({ dataField: field, text: text });
        }

        //Create data
        const tableData = [];
        for (const node of metaData.selectedNodes) {
            const label = node.label;
            const location_node_id = node.location_node_id;

            if (plotData[location_node_id] == null) continue;

            const avg_data = { label: label, measurement: "Average", id: label + " Average" };
            const min_data = { label: label, measurement: "Min", id: label + " Min" };
            const max_data = { label: label, measurement: "Max", id: label + " Max" };

            for (const subplot of selectedParameters) {
                const field = parameters[subplot]["tableField"];
                if (plotData[location_node_id][field]) {
                    avg_data[field] = plotData[location_node_id][`${field}_mean`];
                    min_data[field] = plotData[location_node_id][`${field}_min`];
                    max_data[field] = plotData[location_node_id][`${field}_max`];
                } else {
                    avg_data[field] = "---";
                    min_data[field] = "---";
                    max_data[field] = "---";
                }
            }
            tableData.push(avg_data);
            tableData.push(min_data);
            tableData.push(max_data);
        }

        const table = {
            columns: columns,
            data: tableData,
            keyField: "id",
        };

        return { plot, table };
    }
}

class AccumulatedParser {
    static parseAmpOverSF(plotData, selectedEquipment, plotWithEstimationFlag, metaData) {
        const ampOverSFData = plotData?.amp_over_sf_daily;

        if (!ampOverSFData || Object.keys(ampOverSFData).length === 0 || selectedEquipment == null)
            return { data: [], layout: {} };

        const [rangeStart, rangeEnd] = getDateRange(metaData.startDate, metaData.endDate);

        const layout = {
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            xaxis: {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: "Date - Time",
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
                range: [rangeStart, rangeEnd],
            },
            yaxis: {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: "Amp hours",
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            },
            title: {
                font: { size: 22 },
                x: 0.07,
                align: "top",
                text: "Amp hours over Service Factor",
            },
            legend: { font: { size: 18 } },
            showlegend: true,
            grid: { rows: 1, columns: 1 },
            colorway: ["blue", "red", "orange", "green", "purple"],
        };

        //Add equipment metaData to API response data for convenience
        for (const eq of selectedEquipment) {
            const location_node_id = eq["location_node_id"];

            if (ampOverSFData[location_node_id] == null) continue;

            ampOverSFData[location_node_id]["motor_change_epochs"] = eq["motor_change_epoch"];
            ampOverSFData[location_node_id]["label"] = eq["label"];
        }

        const data = [];
        for (const [location_node_id, nodeData] of Object.entries(ampOverSFData)) {
            if (nodeData == null || nodeData.day.length <= 0) continue;

            const x = [];
            const y = [];

            //motor change epochs are given in seconds, Date() constructor takes ms
            //ISO string is the best way to represent dates, slice(0,10) gives just year, month, day -> yyyy-mm-dd
            const motorChangeDates = Array.isArray(nodeData.motor_change_epochs)
                ? nodeData.motor_change_epochs.map((epoch) => new Date(epoch * 1000).toISOString().slice(0, 10))
                : [];

            var motorChangeIdx = 0;
            var runningTotal = 0;

            //If the first motor change occurs before we have data -> create estimated data based on first 10 days after recorded data
            if (
                plotWithEstimationFlag === true &&
                nodeData.motor_change_epochs.length > 0 &&
                motorChangeDates[0] < nodeData.day[0]
            ) {
                const x1 = new Date(nodeData["day"][0]).getTime() / 1000; //convert milliseconds to seconds
                const x2 = new Date(nodeData["day"][10]).getTime() / 1000;
                const y1 = nodeData["amp_hours_sf"][0];
                const y2 = nodeData["amp_hours_sf"]
                    .slice(0, 11)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                const slope = (y2 - y1) / (x2 - x1);

                //Add first day beginning at the first motor change
                x.push(motorChangeDates[0]);
                y.push(0);

                motorChangeIdx = 1;
                while (
                    motorChangeIdx < motorChangeDates.length &&
                    motorChangeDates[motorChangeIdx] < nodeData["day"][0]
                ) {
                    const estimation =
                        slope *
                        (nodeData.motor_change_epochs[motorChangeIdx] -
                            nodeData.motor_change_epochs[motorChangeIdx - 1]);

                    //Add the estimation and then 0 for the next day after the motor has been changed
                    x.push(motorChangeDates[motorChangeIdx]);
                    y.push(estimation);

                    const d = new Date(motorChangeDates[motorChangeIdx]);
                    d.setUTCDate(d.getUTCDate() + 1);
                    x.push(d.toISOString().slice(0, 10));
                    y.push(0);

                    motorChangeIdx++;
                }

                //Add the estimation that occurs between the last motor change that occurs before data start and the day before data start
                const estimation = slope * (x1 - 86400 - nodeData.motor_change_epochs[motorChangeIdx - 1]);

                const d = new Date(x1 * 1000);
                d.setUTCDate(d.getUTCDate() - 1);

                x.push(d.toISOString().slice(0, 10));
                y.push(estimation);
                runningTotal += estimation;
            } else {
                //If no motor change occurs before data starts - add a 0 on day before data starts for better plotting
                const day0 = new Date(nodeData["day"][0]);
                day0.setUTCDate(day0.getUTCDate() - 1);
                x.push(day0.toISOString().slice(0, 10));
                y.push(0);

                //Move motorIdx to correct spot if plotting w/o estimation
                while (motorChangeIdx < motorChangeDates.length && motorChangeDates[motorChangeIdx] < nodeData.day[0])
                    motorChangeIdx++;
            }

            //Plotting once data has started
            //Calc and add running total for each day in data - reset if motor change occurs
            var dayIdx = 0;
            while (dayIdx < nodeData.day.length) {
                const amp_hours_sf = nodeData.amp_hours_sf[dayIdx];
                const day = nodeData.day[dayIdx];

                if (motorChangeIdx < motorChangeDates.length && motorChangeDates[motorChangeIdx] < day) {
                    //Reset on motor change
                    runningTotal = 0;
                    x.push(motorChangeDates[motorChangeIdx]);
                    y.push(0);
                    motorChangeIdx++;
                }

                runningTotal += amp_hours_sf;
                x.push(day);
                y.push(runningTotal);

                dayIdx++;
            }

            const trace = {
                x: x,
                y: y,
                yaxis: `y`,
                type: "scatter",
                name: nodeData["label"],
                mode: "lines+markers",
            };
            data.push(trace);
        }

        return { layout, data };
    }

    static parseStarts(plotData, selectedEquipment, plotWithEstimationFlag, metaData) {
        const startsData = plotData?.starts_daily;

        if (!startsData || Object.keys(startsData).length === 0 || selectedEquipment == null)
            return { data: [], layout: {} };

        const [rangeStart, rangeEnd] = getDateRange(metaData.startDate, metaData.endDate);

        const layout = {
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            xaxis: {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: "Date - Time",
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
                range: [rangeStart, rangeEnd],
            },
            yaxis: {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: "Starts",
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            },
            title: {
                font: { size: 22 },
                x: 0.07,
                align: "top",
                text: "Accumulation of Starts",
            },
            legend: { font: { size: 18 } },
            showlegend: true,
            colorway: ["blue", "red", "orange", "green", "purple"],
        };

        //Add equipment metaData to API response data for convenience
        for (const eq of selectedEquipment) {
            const location_node_id = eq["location_node_id"];

            if (startsData[location_node_id] == null) continue;

            startsData[location_node_id]["motor_change_epochs"] = eq["motor_change_epoch"];
            startsData[location_node_id]["label"] = eq["label"];
        }

        const data = [];
        for (const [location_node_id, nodeData] of Object.entries(startsData)) {
            if (nodeData == null || nodeData.day.length <= 0) continue;

            const x = [];
            const y = [];

            //motor change epochs are given in seconds, Date() constructor takes ms
            //ISO string is the best way to represent dates, slice(0,10) gives just year, month, day -> yyyy-mm-dd
            const motorChangeDates = Array.isArray(nodeData.motor_change_epochs)
                ? nodeData.motor_change_epochs.map((epoch) => new Date(epoch * 1000).toISOString().slice(0, 10))
                : [];

            var motorChangeIdx = 0;
            var runningTotal = 0;

            //If the first motor change occurs before we have data -> create estimated data based on first 10 days after recorded data
            if (
                plotWithEstimationFlag === true &&
                nodeData.motor_change_epochs.length > 0 &&
                motorChangeDates[0] < nodeData.day[0]
            ) {
                const x1 = new Date(nodeData["day"][0]).getTime() / 1000; //convert milliseconds to seconds
                const x2 = new Date(nodeData["day"][10]).getTime() / 1000;
                const y1 = nodeData["starts"][0];
                const y2 = nodeData["starts"]
                    .slice(0, 11)
                    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                const slope = (y2 - y1) / (x2 - x1);

                //Add first day beginning at the first motor change
                x.push(motorChangeDates[0]);
                y.push(0);

                motorChangeIdx = 1;
                while (
                    motorChangeIdx < motorChangeDates.length &&
                    motorChangeDates[motorChangeIdx] < nodeData["day"][0]
                ) {
                    const estimation = Math.round(
                        slope *
                            (nodeData.motor_change_epochs[motorChangeIdx] -
                                nodeData.motor_change_epochs[motorChangeIdx - 1])
                    );

                    //Add the estimation and then 0 for the next day after the motor has been changed
                    x.push(motorChangeDates[motorChangeIdx]);
                    y.push(estimation);

                    const d = new Date(motorChangeDates[motorChangeIdx]);
                    d.setUTCDate(d.getUTCDate() + 1);
                    x.push(d.toISOString().slice(0, 10));
                    y.push(0);

                    motorChangeIdx++;
                }

                //Add the estimation that occurs between the last motor change that occurs before data start and the day before data start
                const estimation = Math.round(slope * (x1 - 86400 - nodeData.motor_change_epochs[motorChangeIdx - 1]));

                const d = new Date(x1 * 1000);
                d.setUTCDate(d.getUTCDate() - 1);

                x.push(d.toISOString().slice(0, 10));
                y.push(estimation);
                runningTotal += estimation;
            } else {
                //If no motor change occurs before data starts - add a 0 on day before data starts for better plotting
                const day0 = new Date(nodeData["day"][0]);
                day0.setUTCDate(day0.getUTCDate() - 1);
                x.push(day0.toISOString().slice(0, 10));
                y.push(0);

                //Move motorIdx to correct spot if plotting w/o estimation
                while (motorChangeIdx < motorChangeDates.length && motorChangeDates[motorChangeIdx] < nodeData.day[0])
                    motorChangeIdx++;
            }

            //Plotting once data has started
            //Calc and add running total for each day in data - reset if motor change occurs
            var dayIdx = 0;
            while (dayIdx < nodeData.day.length) {
                const starts = nodeData.starts[dayIdx];
                const day = nodeData.day[dayIdx];

                if (motorChangeIdx < motorChangeDates.length && motorChangeDates[motorChangeIdx] < day) {
                    //Reset on motor change
                    runningTotal = 0;
                    x.push(motorChangeDates[motorChangeIdx]);
                    y.push(0);
                    motorChangeIdx++;
                }

                runningTotal += starts;
                x.push(day);
                y.push(runningTotal);

                dayIdx++;
            }

            const trace = {
                x: x,
                y: y,
                yaxis: `y`,
                type: "scatter",
                name: nodeData["label"],
                mode: "lines+markers",
            };
            data.push(trace);
        }

        return { layout, data };
    }
}

class FifteenMinuteParser {
    static parseData(plotData, metaData) {
        const parametersDict = {
            Voltage: {
                plotName: "Voltage",
                yAxisName: "Voltage (V)",
                plotFields: [], //Assign in preprocesing section depending on L-N/L-L/DC
                legendFields: [], //""
                traceColor: [], //""
                tableName: "Voltage",
                tableField: "voltage",
            },
            Current: {
                plotName: "Current",
                yAxisName: "Current (A)",
                plotFields: ["current", "current_a", "current_b", "current_c"],
                legendFields: ["Current", "Current-A", "Current-B", "Current-C"],
                traceColor: ["green", "black", "red", "blue"],
                tableName: "Current",
                tableField: "current",
            },
            "Line Frequency": {
                plotName: "Line Frequency",
                yAxisName: "Line Frequency",
                plotFields: ["line_frequency"],
                legendFields: ["Line Frequency"],
                traceColor: ["green"],
                tableName: "Line Frequency",
                tableField: "line_frequency",
            },
            "Power Factor": {
                plotName: "Power Factor ZC",
                yAxisName: "Power Factor ZC",
                plotFields: ["power_factor"],
                legendFields: ["Power Factor ZC"],
                traceColor: ["green"],
                tableName: "Power Factor",
                tableField: "power_factor",
            },
            "Voltage Imbalance": {
                plotName: "Voltage Imbalance",
                yAxisName: "Voltage Imbalance",
                plotFields: ["voltage_imbalance"],
                legendFields: ["Voltage Imbalance"],
                traceColor: ["green"],
                tableName: "Voltage Imbalance",
                tableField: "voltage_imbalance",
            },
            "Current Imbalance": {
                plotName: "Current Imbalance",
                yAxisName: "Current Imbalance",
                plotFields: ["current_imbalance"],
                legendFields: ["Current Imbalance"],
                traceColor: ["green"],
                tableName: "Current Imbalance",
                tableField: "current_imbalance",
            },
            "Voltage THD": {
                plotName: "Voltage THD",
                yAxisName: "Voltage THD",
                plotFields: ["voltage_thd"],
                legendFields: ["Voltage THD"],
                traceColor: ["green"],
                tableName: "Voltage THD",
                tableField: "voltage_thd",
            },
            "Current THD": {
                plotName: "Current THD",
                yAxisName: "Current THD",
                plotFields: ["current_thd"],
                legendFields: ["Current THD"],
                traceColor: ["green"],
                tableName: "Current THD",
                tableField: "current_thd",
            },
            "V-Peaks": {
                plotName: "V-Peaks",
                yAxisName: "Voltage (V)",
                plotFields: ["voltage", "v_peak", "v_peak_avg"],
                legendFields: ["Voltage", "V-Peak", "V-Peak-Avg"],
                traceColor: ["green", "red", "black"],
                tableName: "V-Peaks",
                tableField: "v_peak",
            },
            "I-Peaks": {
                plotName: "I-Peaks",
                yAxisName: "Current (A)",
                plotFields: ["current", "i_peak", "i_peak_avg"],
                legendFields: ["Current", "I-Peak", "I-Peak-Avg"],
                traceColor: ["green", "red", "black"],
                tableName: "I-Peaks",
                tableField: "i_peak",
            },
            "Voltage Ripple": {
                plotName: "Voltage Ripple",
                yAxisName: "Voltage Ripple",
                plotFields: ["voltage_ripple", "voltage_ripple_a", "voltage_ripple_b", "voltage_ripple_c"],
                legendFields: ["Voltage Ripple", "Voltage Ripple-A", "Voltage Ripple-B", "Voltage Ripple-C"],
                traceColor: ["green", "black", "red", "blue"],
                tableName: "Voltage Ripple",
                tableField: "voltage_ripple",
            },
            "Current Ripple": {
                plotName: "Current Ripple",
                yAxisName: "Current Ripple",
                plotFields: ["current_ripple", "current_ripple_a", "current_ripple_b", "current_ripple_c"],
                legendFields: ["Current Ripple", "Current Ripple-A", "Current Ripple-B", "Current Ripple-C"],
                traceColor: ["green", "black", "red", "blue"],
                tableName: "Current Ripple",
                tableField: "current_ripple",
            },
            "Crest Factor": {
                plotName: "Crest Factor",
                yAxisName: "Crest Factor",
                plotFields: ["crest_factor"],
                legendFields: ["Crest Factor"],
                traceColor: ["green"],
                tableName: "Crest Factor",
                tableField: "crest_factor",
            },
            "HP ZC": {
                plotName: "HP ZC",
                yAxisName: "HP",
                plotFields: ["hp"],
                legendFields: ["HP ZC"],
                traceColor: ["green"],
                tableName: "HP",
                tableField: "hp",
            },
            "V/I": {
                plotName: "V/I",
                yAxisName: "V/I (Ω)",
                plotFields: ["v_i"],
                legendFields: ["V/I"],
                traceColor: ["green"],
                tableName: "V/I",
                tableField: "v_i",
            },
            "V/I Filtered": {
                plotName: "V/I Filtered",
                yAxisName: "V/I Filtered (Ω)",
                plotFields: ["v_i_filtered"],
                legendFields: ["V/I Filtered"],
                traceColor: ["green"],
                tableName: "V/I Filtered",
                tableField: "v_i_filtered",
            },
            "Field Voltage": {
                plotName: "Field Voltage",
                yAxisName: "Field Voltage (V)",
                plotFields: ["field_voltage"],
                legendFields: ["Field Voltage"],
                traceColor: ["green"],
                tableName: "Field Voltage",
                tableField: "field_voltage",
            },
            "Field Current": {
                plotName: "Field Current",
                yAxisName: "Field Current (A)",
                plotFields: ["field_current"],
                legendFields: ["Field Current"],
                traceColor: ["green"],
                tableName: "Field Current",
                tableField: "field_current",
            },
            "Node Connected": {
                plotName: "Node Connected",
                yAxisName: "Connection Status",
                plotFields: ["connected"],
                legendFields: ["Node Connected"],
                traceColor: ["green"],
                tableName: "Node Connected",
                tableField: null,
            },
            "I/Ifield": {
                plotName: "I/I<sub>field</sub>",
                yAxisName: "I/I<sub>field</sub>",
                plotFields: ["I/Ifield"],
                legendFields: ["I/I<sub>field</sub>"],
                traceColor: ["green"],
                tableName: (
                    <>
                        I/I<sub>field</sub>
                    </>
                ),
                tableField: "I/Ifield",
            },
            "Current Spike Count": {
                plotName: "Current Spike Count",
                yAxisName: "Count",
                plotFields: ["current_spike_count"],
                legendFields: ["Current Spike Count"],
                traceColor: ["green"],
                tableName: "Current Spike Count",
                tableField: null,
            },
            "Raw Power Factor": {
                plotName: "Power Factor",
                yAxisName: "",
                plotFields: ["raw_power_factor"],
                legendFields: ["Power Factor"],
                traceColor: ["green"],
                tableName: "Power Factor",
                tableField: null,
            },
            "Apparent Power (kVA)": {
                plotName: "Apparent Power (kVA)",
                yAxisName: "kVA",
                plotFields: ["kva"],
                legendFields: ["kVA"],
                traceColor: ["green"],
                tableName: "Apparent Power (kVA)",
                tableField: null,
            },
            "Reactive Power (kVAR)": {
                plotName: "Reactive Power (kVAR)",
                yAxisName: "kVAR",
                plotFields: ["kvar"],
                legendFields: ["kVAR"],
                traceColor: ["green"],
                tableName: "Reactive Power (kVAR)",
                tableField: null,
            },
            "Active Power (kW)": {
                plotName: "Active Power (kW)",
                yAxisName: "kW",
                plotFields: ["kw"],
                legendFields: ["kW"],
                traceColor: ["green"],
                tableName: "Active Power (kW)",
                tableField: null,
            },
            "RAW HP": {
                plotName: "HP",
                yAxisName: "HP",
                plotFields: ["raw_hp"],
                legendFields: ["HP"],
                traceColor: ["green"],
                tableName: "HP",
                tableField: null,
            },
            "Node Internal Temperature": {
                plotName: "Node Internal Temperature",
                yAxisName: "°C",
                plotFields: ["node_temp_celsius"],
                legendFields: ["Node Internal Temperature"],
                traceColor: ["green"],
                tableName: "Node Internal Temperature",
                tableField: null,
            },
            "di/dt": {
                plotName: "di/dt",
                yAxisName: "di/dt",
                plotFields: ["ia_max_di_dt", "ib_max_di_dt", "ic_max_di_dt"],
                legendFields: ["di/dt-A", "di/dt-B", "di/dt-C"],
                traceColor: ["black", "red", "blue"],
                tableName: "di/dt",
                tableField: null,
            },
            Inductance: {
                plotName: "Inductance",
                yAxisName: "Inductance (H)",
                plotFields: ["inductance", "inductance_a", "inductance_b", "inductance_c"],
                legendFields: ["Inductance", "Inductance - A", "Inductance - B", "Inductance - C"],
                traceColor: ["green", "black", "red", "blue"],
                tableName: "Inductance",
                tableField: null,
            },

            "Inductance Imbalance": {
                plotName: "Inductance Imbalance",
                yAxisName: "Inductance Imbalance %",
                plotFields: ["inductance_imbalance_a", "inductance_imbalance_b", "inductance_imbalance_c"],
                legendFields: ["Inductance Imbalance - A", "Inductance Imbalance - B", "Inductance Imbalance - C"],
                traceColor: ["black", "red", "blue"],
                tableName: "Inductance Imbalance",
                tableField: null,
            },

            Resistance: {
                plotName: "Resistance",
                yAxisName: "Resistance (Ω)",
                plotFields: ["resistance", "resistance_a", "resistance_b", "resistance_c"],
                legendFields: ["Resistance", "Resistance A", "Resistance B", "Resistance C"],
                traceColor: ["green", "black", "red", "blue"],
                tableName: "Resistance",
                tableField: "resistance",
            },

            "Resistive Imbalance": {
                plotName: "Resistive Imbalance",
                yAxisName: "Resistive Imbalance %",
                plotFields: ["resistive_imbalance_a", "resistive_imbalance_b", "resistive_imbalance_c"],
                legendFields: ["Resistive Imbalance A", "Resistive Imbalance B", "Resistive Imbalance C"],
                traceColor: ["black", "red", "blue"],
                tableName: "Resistive Imbalance",
                tableField: null,
            },
        };

        const selectedParameters = metaData.selectedParameters
            .map((param) => param.value)
            .filter((param) => param in parametersDict);

        // if (!("time" in plotData) || plotData["time"] == null || plotData["time"].length === 0) {
        //     return {
        //         plot: {
        //             data: [],
        //             layout: {},
        //         },
        //         table: {
        //             data: [],
        //             columns: [{ dataField: "", text: "" }],
        //             keyField: "measurement",
        //         },
        //     };
        // }

        const selectedNodeCount = Object.keys(metaData.selectedNodes).length;
        const subplotCount = Object.keys(selectedParameters).length;

        const titleHeight = 27 + selectedNodeCount * 28.6;
        const titleMargin = titleHeight + 44.4;
        const plotHeight = (subplotCount === 1 ? 400 : subplotCount * 300) + titleMargin;
        const titleLocation = 1 - 30 / plotHeight;

        const plotTitle =
            metaData.selectedNodes
                .map((eq) => {
                    return (
                        eq.plot_title_node_label +
                        ` (${eq.np_voltage}V, ${eq.np_current}A, ${eq.np_rpm}RPM, ${eq.np_hp}HP)`
                    );
                })
                .join("<br>") +
            "<br>Date: " +
            metaData.startDate +
            " to " +
            metaData.endDate +
            " " +
            new Date()
                .toLocaleDateString("en-US", {
                    timeZone: metaData.timezone,
                    timeZoneName: "short",
                })
                .slice(-3);

        const [rangeStart, rangeEnd] = getDateRange(metaData.startDate, metaData.endDate);

        const layout = {
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            xaxis: {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: "Date - Time",
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
                range: [rangeStart, rangeEnd],
            },
            title: {
                font: { size: 22 },
                x: 0.07,
                align: "top",
                text: plotTitle,
                y: titleLocation,
                yref: "container",
            },
            legend: { font: { size: 18 } },
            showlegend: true,
            margin: {
                t: titleMargin,
            },
        };

        //-------Pre-Processing-------//
        var data = [];
        var annotations = [];
        var nodeCount = 0;
        const colorway = [
            "blue",
            "orange",
            "green",
            "purple",
            "#1f77b4", // muted blue
            "#F56600", // darker orange
            "#8c564b", // chestnut brown
            "#e377c2", // raspberry yogurt pink
            "#7f7f7f", // middle gray
            "#17becf", // blue-teal
        ];
        for (const node of metaData.selectedNodes) {
            const location_node_id = node.location_node_id;
            if (
                plotData[location_node_id] == null ||
                plotData[location_node_id]["time"] == null ||
                plotData[location_node_id]["time"].length === 0
            ) {
                continue;
            }

            const parameters = JSON.parse(JSON.stringify(parametersDict)); // Deep Copy the list for each node so preprocessing doesn't overwrite
            for (const param of selectedParameters) {
                if (param === "V/I") {
                    //Create V/I data

                    const plotField = parameters[param]["plotFields"][0];
                    if (
                        plotData[location_node_id]["voltage"] == null ||
                        plotData[location_node_id]["current"] == null
                    ) {
                        console.log("Missing data requirements for the V/I plot");
                        continue;
                    }

                    const v_i = Array(plotData[location_node_id]["voltage"].length);
                    const v_noise = node.v_noise;
                    const i_noise = node.i_noise;
                    for (var i = 0; i < plotData[location_node_id]["voltage"].length; i++) {
                        //Finds V/I and rounds to 3 digits
                        if (
                            plotData[location_node_id]["voltage"][i] == null ||
                            plotData[location_node_id]["current"][i] == null
                        ) {
                            v_i[i] = null;
                        } else if (
                            plotData[location_node_id]["voltage"][i] < v_noise ||
                            plotData[location_node_id]["current"][i] < i_noise
                        ) {
                            v_i[i] = 0;
                        } else {
                            v_i[i] =
                                Math.round(
                                    (plotData[location_node_id]["voltage"][i] /
                                        plotData[location_node_id]["current"][i]) *
                                        1e3
                                ) / 1e3;
                        }
                    }
                    plotData[location_node_id][plotField] = v_i;
                } else if (param === "V/I Filtered") {
                    //Create V/I Filtered data
                    const plotField = parameters[param]["plotFields"][0];
                    if (
                        plotData[location_node_id]["voltage"] == null ||
                        plotData[location_node_id]["current"] == null
                    ) {
                        console.log("Missing data requirements for the V/I plot");
                        continue;
                    }

                    const v_i = Array(plotData[location_node_id]["voltage"].length);
                    const i_noise = node.i_noise;
                    for (var i = 0; i < plotData[location_node_id]["voltage"].length; i++) {
                        //Finds V/I and rounds to 3 digits
                        if (
                            plotData[location_node_id]["voltage"][i] == null ||
                            plotData[location_node_id]["current"][i] == null ||
                            plotData[location_node_id]["voltage"][i] < node.vi_minimum_voltage_threshold
                        ) {
                            v_i[i] = null;
                        } else if (plotData[location_node_id]["current"][i] < i_noise) {
                            v_i[i] = 0;
                        } else {
                            v_i[i] =
                                Math.round(
                                    (plotData[location_node_id]["voltage"][i] /
                                        plotData[location_node_id]["current"][i]) *
                                        1e3
                                ) / 1e3;
                        }
                    }
                    plotData[location_node_id][plotField] = v_i;
                } else if (param === "Voltage") {
                    if (metaData.additionalNodesFlag) {
                        parameters["Voltage"]["plotFields"] = ["voltage"];
                        parameters["Voltage"]["legendFields"] = ["Voltage"];
                        parameters["Voltage"]["traceColor"] = ["black"];
                        parameters["Voltage"]["tableName"] = "Voltage";
                    } else if (node.eq_type_sub === "v1") {
                        parameters["Voltage"]["plotFields"] = ["voltage"];
                        parameters["Voltage"]["legendFields"] = ["Voltage"];
                        parameters["Voltage"]["traceColor"] = ["black"];
                        parameters["Voltage"]["tableName"] = "Voltage";
                    } else if (node.eq_type_sub === "i1") {
                        parameters["Voltage"]["plotFields"] = ["voltage"];
                        parameters["Voltage"]["legendFields"] = ["Voltage"];
                        parameters["Voltage"]["traceColor"] = ["black"];
                        parameters["Voltage"]["tableName"] = "Voltage";
                    } else if (node.eq_type === "dc") {
                        parameters["Voltage"]["plotFields"] = ["voltage"];
                        parameters["Voltage"]["legendFields"] = ["Voltage DC"];
                        parameters["Voltage"]["traceColor"] = ["black"];
                        parameters["Voltage"]["tableName"] = "Voltage DC";
                    } else if (metaData.v_type === "L-N") {
                        parameters["Voltage"]["plotFields"] = ["voltage", "voltage_a", "voltage_b", "voltage_c"];
                        parameters["Voltage"]["legendFields"] = ["Voltage L-N", "Voltage-A", "Voltage-B", "Voltage-C"];
                        parameters["Voltage"]["traceColor"] = ["green", "black", "red", "blue"];
                        parameters["Voltage"]["tableName"] = "Voltage L-N";
                    } else {
                        parameters["Voltage"]["plotFields"] = ["voltage", "voltage_ab", "voltage_bc", "voltage_ca"];
                        parameters["Voltage"]["legendFields"] = [
                            "Voltage L-L",
                            "Voltage-AB",
                            "Voltage-BC",
                            "Voltage-CA",
                        ];
                        parameters["Voltage"]["traceColor"] = ["green", "black", "red", "blue"];
                        parameters["Voltage"]["tableName"] = "Voltage L-L";
                    }
                } else if (param === "Current") {
                    if (metaData.additionalNodesFlag) {
                        parameters["Current"]["plotFields"] = parameters["Current"]["plotFields"].slice(0, 1);
                        parameters["Current"]["legendFields"] = parameters["Current"]["legendFields"].slice(0, 1);
                        parameters["Current"]["traceColor"] = parameters["Current"]["traceColor"].slice(0, 1);
                    } else if (node.eq_type === "dc") {
                        const postfix = " DC";
                        parameters["Current"]["legendFields"][0] += postfix;
                        parameters["Current"]["plotFields"] = parameters["Current"]["plotFields"].slice(0, 1);
                        parameters["Current"]["legendFields"] = parameters["Current"]["legendFields"].slice(0, 1);
                        parameters["Current"]["traceColor"] = parameters["Current"]["traceColor"].slice(0, 1);
                        parameters["Current"]["tableName"] += postfix;
                    } else if (node.eq_type_sub === "i1") {
                        parameters["Current"]["plotFields"] = ["current"];
                        parameters["Current"]["legendFields"] = ["Current"];
                        parameters["Current"]["traceColor"] = ["black"];
                        parameters["Current"]["tableName"] = "Current";
                    }
                } else if (param === "I/Ifield") {
                    //Create I/Ifield data
                    const plotField = parameters[param]["plotFields"][0];
                    if (
                        plotData[location_node_id]["field_current_a"] == null ||
                        plotData[location_node_id]["current"] == null
                    ) {
                        console.log("Missing data requirements for the V/I plot");
                        continue;
                    }

                    const i_ifield = Array(plotData[location_node_id]["current"].length);
                    const i_noise = node.i_noise;
                    for (var i = 0; i < plotData[location_node_id]["current"].length; i++) {
                        if (plotData[location_node_id]["field_current_a"][i] === 0) {
                            plotData[location_node_id]["field_current_a"][i] = 0.00001;
                        }

                        var current;
                        if (node.eq_type === "dc") {
                            current = plotData[location_node_id]["current_a"][i];
                        } else {
                            current =
                                Math.round(
                                    ((plotData[location_node_id]["current_a"][i] +
                                        plotData[location_node_id]["current_b"][i] +
                                        plotData[location_node_id]["current_c"][i]) /
                                        3) *
                                        1e3
                                ) / 1e3;
                        }

                        i_ifield[i] =
                            current > i_noise
                                ? Math.round((current / plotData[location_node_id]["field_current_a"][i]) * 1e3) / 1e3
                                : 0;
                    }
                    plotData[location_node_id][plotField] = i_ifield;
                } else if (param == "V-Peaks") {
                    if (metaData.v_type === "L-N") {
                        parameters["V-Peaks"]["legendFields"] = ["Voltage L-N", "V-Peaks L-N", "V-Peaks-Avg L-N"];
                    } else {
                        parameters["V-Peaks"]["legendFields"] = ["Voltage L-L", "V-Peaks L-L", "V-Peaks-Avg L-L"];
                    }
                }
            }

            //-------Creating plots-------//
            const x = plotData[location_node_id].time;
            var count = 0;
            for (const subplot of selectedParameters) {
                if (plotData[location_node_id][parameters[subplot]["plotFields"][0]] == null) continue;

                count++;

                //Format if Y-axis name is too long
                //Replace ignores any html tags so only display name is measured
                var yTitle = parameters[subplot]["yAxisName"];
                if (yTitle.replace(/(<([^>]+)>)/gi, "").length > 20) {
                    //replace the space at arbitrary point near middle of text with a break
                    const index = yTitle.replace(/(<([^>]+)>)/gi, "").indexOf(" ", Math.floor(yTitle.length / 2) - 3);
                    yTitle = yTitle.substring(0, index) + "<br>" + yTitle.substring(index + 1);
                }

                layout[`yaxis${count}`] = {
                    title: {
                        font: { size: 22 },
                        standoff: 20,
                        x: 0.0,
                        text: yTitle,
                    },
                    automargin: true,
                    gridcolor: "white",
                    linecolor: "white",
                    zeroline: false,
                    tickfont: {
                        size: 22,
                    },
                    categoryorder: "category ascending",
                };
                const subplotTitle = {
                    text: parameters[subplot]["plotName"],
                    font: { size: 18 },
                    xref: "paper",
                    yref: `y${count} domain`,
                    x: 0.5,
                    y: 0.99,
                    yanchor: "bottom",
                    showarrow: false,
                };
                annotations.push(subplotTitle);
                for (const [idx, field] of parameters[subplot]["plotFields"].entries()) {
                    if (plotData[location_node_id][field] == null) continue;

                    const trace = {
                        x: x,
                        y: plotData[location_node_id][field],
                        yaxis: `y${count}`,
                        type: "scatter",
                        name: metaData.additionalNodesFlag ? node.label : parameters[subplot]["legendFields"][idx],
                        csvName:
                            (metaData.additionalNodesFlag ? node.label + " - " : "") +
                            parameters[subplot]["legendFields"][idx],
                        marker: {
                            color: metaData.additionalNodesFlag
                                ? colorway[nodeCount % colorway.length]
                                : parameters[subplot]["traceColor"][idx],
                        },
                        legendgroup: metaData.additionalNodesFlag
                            ? `${location_node_id}`
                            : subplot + parameters[subplot]["legendFields"][idx],
                        showlegend: metaData.additionalNodesFlag ? count === 1 && idx === 0 : true,
                    };
                    const fillerTrace = {
                        x: x,
                        y: plotData[location_node_id][field],
                        yaxis: `y${count}`,
                        type: "scatter",
                        name: metaData.additionalNodesFlag ? node.label : parameters[subplot]["legendFields"][idx],
                        marker: {
                            color: metaData.additionalNodesFlag
                                ? colorway[nodeCount % colorway.length]
                                : parameters[subplot]["traceColor"][idx],
                        },
                        legendgroup: metaData.additionalNodesFlag
                            ? `${location_node_id}`
                            : subplot + parameters[subplot]["legendFields"][idx],
                        showlegend: false,
                        line: {
                            dash: "2px,4px",
                            width: "2",
                        },
                        connectgaps: true,
                        // hoverinfo: "skip",
                        // hovermode: false,
                    };
                    data.push(trace, fillerTrace);

                    //Add NP + SF trace for current plot
                    if (field === "current" && metaData.additionalNodesFlag === false) {
                        const eq = node;
                        const yVal = parseFloat((eq.np_sf === 0.1 ? 1.15 : eq.np_sf) * eq.np_current);
                        const npsf_trace = {
                            x: [x[0], x.slice(-1)[0]],
                            y: [yVal, yVal],
                            yaxis: `y${count}`,
                            type: "scatter",
                            name: "NP + SF",
                            marker: { color: "black" },
                        };
                        data.push(npsf_trace);
                    }
                }
                // Add notes to first subplot
                if (count === 1) {
                    const max_y_plot = data.reduce((max, curr) => {
                        // Need additional reduce because return Math.max(...curr.y, max) will exceed stack
                        const localMax = curr.y.reduce((max, curr) => Math.max(max, curr), -Infinity);
                        return Math.max(localMax, max);
                    }, -Infinity);

                    const notes = plotData[location_node_id]?.notes?.x.map((time, i) => {
                        const max_y_at_index = data.reduce((max, curr) => {
                            if (curr["name"] === "NP + SF") {
                                return max;
                            }

                            return Math.max(max, estimateY(time, curr));
                        }, 0);

                        const text = formatText(plotData[location_node_id].notes.text[i], 30);

                        return {
                            x: time,
                            y: max_y_at_index,
                            xref: "x",
                            yref: "y",
                            text: text,
                            showarrow: true,
                            arrowhead: 0,
                            ayref: "y",
                            ay: max_y_plot,
                            yanchor: "bottom",
                            yshift: 12,
                            ax: 0,
                        };
                    });
                    if (notes) annotations = annotations.concat(notes);
                }
            }
            nodeCount++;
        }

        layout["grid"] = { rows: count, columns: 1 };
        layout["height"] = count === 1 ? 400 : count * 300;
        layout["annotations"] = annotations;

        const plot = { layout, data };

        //----Creating Table----//
        function tableFormat(cell, row, rowIndex, colIndex) {
            if (rowIndex % 3) {
                return { style: { display: "none" } };
            } else {
                return { rowSpan: 3 };
            }
        }
        const columns = [
            { dataField: "label", text: "Node SN", attrs: tableFormat },
            { dataField: "measurement", text: "" },
        ];

        //Create columns
        for (const subplot of selectedParameters) {
            const text = parametersDict[subplot]["tableName"];
            const field = parametersDict[subplot]["tableField"];

            columns.push({ dataField: field, text: text });
        }

        const tableData = [];
        for (const node of metaData.selectedNodes) {
            const label = node.label;
            const location_node_id = node.location_node_id;

            if (plotData[location_node_id] == null) continue;

            const avg_data = { label: label, measurement: "Average" };
            const min_data = { label: label, measurement: "Min" };
            const max_data = { label: label, measurement: "Max" };

            for (const subplot of selectedParameters) {
                const field = parametersDict[subplot]["tableField"];
                if (plotData[location_node_id][field]) {
                    avg_data[field] = plotData[location_node_id][`${field}_mean`];
                    min_data[field] = plotData[location_node_id][`${field}_min`];
                    max_data[field] = plotData[location_node_id][`${field}_max`];
                } else {
                    avg_data[field] = "---";
                    min_data[field] = "---";
                    max_data[field] = "---";
                }
            }
            tableData.push(avg_data);
            tableData.push(min_data);
            tableData.push(max_data);
        }

        const table = {
            columns: columns,
            data: tableData,
            keyField: "measurement",
        };

        return { plot, table };
    }
}

class RelayParser {
    static parameters = {
        //Dictionary of keys/fields to search for
        Voltage: {
            plotFields: ["v_avg_ll"],
            legendFields: ["Voltage"],
            tableField: "v_avg_ll",
            traceColor: "blue",
        },
        Current: {
            plotFields: ["i_avg"],
            legendFields: ["Current"],
            tableField: "i_avg",
            traceColor: "orange",
        },
        "Line Frequency": {
            plotFields: ["line_frequency"],
            legendFields: ["Line Frequency"],
            tableField: "line_frequency",
            traceColor: "green",
        },
        RPM: {
            plotFields: ["rpm"],
            legendFields: ["RPM"],
            tableField: "rpm",
            traceColor: "green",
        },
    };

    /*
	Inputs: 
		plotData: plot_data object returned within the 'getHourlyTrendData' api call
		plotMetaData: metaData containing selectedEquipment, startDate, endDate, and selectedVoltageType.value - Typically set in tabData and given to the plot as a prop
	Outputs:
		Object containing layout and data parameters to be used by a plotly plot
	*/
    static getPlotResponse(plotData, plotMetaData) {
        //console.log(plotData)
        const parameters = this.parameters;
        const plotTitle =
            plotMetaData.selectedEquipment.plot_title_node_label +
            ` (${plotMetaData.selectedEquipment.np_voltage}V, ${plotMetaData.selectedEquipment.np_current}A, ${plotMetaData.selectedEquipment.np_rpm}RPM, ${plotMetaData.selectedEquipment.np_hp}HP)` +
            "<br>Date: " +
            plotMetaData.startDate +
            " to " +
            plotMetaData.endDate +
            " " +
            new Date()
                .toLocaleDateString("en-US", {
                    timeZone: plotMetaData.timezone,
                    timeZoneName: "short",
                })
                .slice(-3);

        const [rangeStart, rangeEnd] = getDateRange(plotMetaData.startDate, plotMetaData.endDate);

        const layout = {
            autosize: true,
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            hoverlabel: { align: "left" },
            hovermode: "closest",
            mapbox: { style: "light" },
            font: { color: "#2a3f5f" },
            xaxis: {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: "Date - Time",
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
                range: [rangeStart, rangeEnd],
            },
            title: {
                font: { size: 22 },
                x: 0.07,
                align: "top",
                text: plotTitle,
            },
            legend: { font: { size: 18 } },
            showlegend: true,
        };

        const markerColors = ["green", "black", "red", "blue"];
        const x = plotData.time;

        var data = [];
        var annotations = [];
        var count = 0;

        for (const subplot in parameters) {
            if (
                plotData[parameters[subplot]["plotFields"][0]] == null ||
                plotData[parameters[subplot]["plotFields"][0]].length <= 0
            )
                continue;

            count++;

            //Format if Y-axis name is too long
            var yTitle = subplot;
            if (yTitle.length > 20) {
                //replace the space at arbitrary point near middle of text with a \n
                const index = yTitle.indexOf(" ", Math.floor(yTitle.length / 2) - 3);
                yTitle = subplot.substring(0, index) + "<br>" + subplot.substring(index + 1);
            }

            layout[`yaxis${count}`] = {
                title: {
                    font: { size: 22 },
                    standoff: 20,
                    x: 0.0,
                    text: yTitle,
                },
                automargin: true,
                gridcolor: "white",
                linecolor: "white",
                zeroline: false,
                tickfont: {
                    size: 22,
                },
            };

            const subplotTitle = {
                text: subplot,
                font: { size: 18 },
                xref: "paper",
                yref: `y${count} domain`,
                x: 0.5,
                y: 0.99,
                yanchor: "bottom",
                showarrow: false,
            };
            annotations.push(subplotTitle);
            for (const [idx, field] of parameters[subplot]["plotFields"].entries()) {
                if (plotData[field] == null || plotData[field].length <= 0) continue;

                const trace = {
                    x: x,
                    y: plotData[field],
                    yaxis: `y${count}`,
                    type: "scatter",
                    name: parameters[subplot]["legendFields"][idx],
                    marker: { color: parameters[subplot]["traceColor"] },
                    connectgaps: true,
                };
                data.push(trace);

                //Add NP + SF trace for current plot
                if (field === "i_avg") {
                    const eq = plotMetaData.selectedEquipment;
                    const yVal = parseFloat((eq.np_sf === 0.1 ? 1.15 : eq.np_sf) * eq.np_current);
                    const npsf_trace = {
                        x: [x[0], x.slice(-1)[0]],
                        y: [yVal, yVal],
                        yaxis: `y${count}`,
                        type: "scatter",
                        name: "NP + SF",
                        marker: { color: "black" },
                    };
                    data.push(npsf_trace);
                }
            }
        }

        layout["grid"] = { rows: count, columns: 1 };
        layout["height"] = count === 1 ? 400 : count * 300;
        layout["annotations"] = annotations;
        return { layout, data };
    }

    /*
	Inputs: Raw table data from 'getHourlyTrendData' api call 
	Outputs: Object containing columns, table data, with a keyfield for a bootstrap table
	*/
    static getTableResponse(tableData) {
        const parameters = this.parameters;

        const columns = [{ dataField: "measurement", text: "" }];

        const avg_data = { measurement: "Average" };
        const min_data = { measurement: "Min" };
        const max_data = { measurement: "Max" };

        for (const subplot in parameters) {
            const field = parameters[subplot]["tableField"];
            const avg = tableData[`${field}_avg`];
            const min = tableData[`${field}_min`];
            const max = tableData[`${field}_max`];
            if (avg != null) {
                columns.push({ dataField: field, text: subplot });
                avg_data[field] = avg;
                min_data[field] = min;
                max_data[field] = max;
            }
        }

        const data = Object.keys(avg_data).length > 1 ? [avg_data, min_data, max_data] : [];

        return {
            columns,
            data,
            keyField: "measurement",
        };
    }
}

export { OneSecNewPlotParser, OneHourParser, OneSecParser, AccumulatedParser, FifteenMinuteParser, RelayParser };
